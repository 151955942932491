import ClickToReveal from '../ClickToReveal/ClickToReveal'
import useStyles from './StakingRateTable.styles'

const StakingRateTable: React.FC = () => {
	const classes = useStyles()

	return (
		<div className={classes.content}>
			<table className={classes.table}>
				<thead>
					<tr>
						<th>Locked Period</th>
						<th>Investor Pass Holder</th>
						<th>Public Staking Rate</th>
					</tr>
				</thead>
				<tbody>
					<ClickToReveal column1Text='30 Days'>
						<tr>
							<td>30 Days</td>
							<td>12% APY</td>
							<td>4.5% APY</td>
						</tr>
					</ClickToReveal>
					<ClickToReveal column1Text='90 Days'>
						<tr>
							<td>90 Days</td>
							<td>15% APY</td>
							<td>7.8% APY</td>
						</tr>
					</ClickToReveal>
					<ClickToReveal column1Text='12 Months'>
						<tr>
							<td>12 Months</td>
							<td>21% APY</td>
							<td>9.25% APY</td>
						</tr>
					</ClickToReveal>
					<ClickToReveal column1Text='24 Months'>
						<tr>
							<td>24 Months</td>
							<td>28% APY</td>
							<td>11% APY</td>
						</tr>
					</ClickToReveal>
					<ClickToReveal column1Text='36 Months'>
						<tr className={classes.red}>
							<td>36 Months</td>
							<td>
								38% APY <p className={classes.small}>(Limited Time Only)</p>
							</td>
							<td>N/A</td>
						</tr>
					</ClickToReveal>
				</tbody>
			</table>
		</div>
	)
}

export default StakingRateTable
