import useStyles, { ClassNames } from './Logo.styles'

const { PUBLIC_URL } = process.env

interface Props {
	className?: ClassNames
}

const CopiumLogo: React.FC<Props> = ({ className }) => {
	const classes = useStyles()

	return (
		<img
			className={classes[className || 'default']}
			src={`${PUBLIC_URL}/img/copium_logo.png`}
			alt="Copium Protocol"
		/>
	)
}

export default CopiumLogo
