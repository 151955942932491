import useStyles from './More.styles'

type IconColor = 'yellow' | 'red' | 'blue'

interface Props {
	color: IconColor
}

const More: React.FC<Props> = ({ color }) => {
	const classes = useStyles()

	const c = color === 'blue' ? 'red' : color

	return (
		<div className={classes.container}>
			<img className={classes.image} src={`img/${c}_hex.png`} />
		</div>
	)
}

export default More
