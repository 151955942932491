import { createUseStyles } from 'react-jss'
import { text_shadow, white } from '../../config/colours'
import { borderRadius, headingFontFamily } from '../../config/jss-vars'

const styles = {
	container: {
		display: 'flex',
		flexDirection: 'column',
		maxWidth: '60rem',
		padding: '0',
		justifyContent: 'center',
		margin: '1rem',
	},
	image: {
		objectFit: 'contain',
		width: '300px',
		height: '300px',
		borderRadius,
	},
	imagePadded : {
		width: '250px',
		height: '250px',
		padding: '25px',
	},
	text: {
		color: white,
		fontFamily: headingFontFamily,
		textShadow: text_shadow,
		textTransform: 'uppercase',
		fontSize: '1.5em',
		padding: '1rem',
		textAlign: 'center',
	},
}

export default createUseStyles(styles)
