export interface Route {
	name: string,
	path: string,
	basePath?: string,
}

export const HomeRoute: Route = {
	name: 'Home',
	path: '/',
}

export const MintRoute: Route = {
	name: 'Mint',
	path: '/mint'
}

export const ICORoute: Route = {
	name: 'ICO',
	path: '/ico'
}

export const InvestorPassRoute: Route = {
	name: 'Investor Pass',
	path: '/investor-pass'
}
