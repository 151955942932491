import { createUseStyles } from 'react-jss'
import { text_shadow, yellow } from '../../config/colours'
import { headingFontFamily } from '../../config/jss-vars'

const styles = {
	container: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '20rem',
		margin: '2rem',
		textAlign: 'center',
	},
	largeContainer: {
		composes: '$container',
		width: '100%',
	},
	title: {
		color: yellow,
		fontFamily: headingFontFamily,
		textShadow: text_shadow,
		fontSize: '1.2rem',
	},
	amount: {
		fontSize: '3rem',
		padding: '1rem',
	},
	smallAmount: {
		fontSize: '2rem',
		padding: '1rem',
	},
	sub: {
		fontSize: '0.8rem',
		fontFamily: headingFontFamily,
		textShadow: text_shadow,
	},
}

export default createUseStyles(styles)
