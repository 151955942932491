import ICOBox from '../../components/ICOBox/ICOBox'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import useStyles from './ICO.styles'

const ICO: React.FC = () => {
	const classes = useStyles()

	return (
		<PageWrapper>
			<div className={classes.page}>
				<ICOBox />
			</div>
		</PageWrapper>
	)
}

export default ICO
