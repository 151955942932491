import { createUseStyles } from 'react-jss'
import { blue } from '../../config/colours'

const styles = {
	container: {
		display: 'flex',
		flexDirection: 'column',
		maxWidth: 'min(60rem, 90vw)',
		padding: '0 1rem',
		justifyContent: 'center',
		alignItems: 'center',
		'& p': {
			width: '100%',
		},
	},
	column: {
		width: '48%',
		maxWidth: '60em',
		'@media (max-width: 768px)': {
			width: '100vw',
		},
	},
	highlight: {
		color: blue,
	},
	title: {
		composes: '$highlight',
		textAlign: 'center',
	},
	centerTitle: {
		composes: '$highlight',
		textAlign: 'center',
		marginTop: '4em',
	},
	paddedBox: {
		padding: '0 2em',
	},
}

export default createUseStyles(styles)
