import { createUseStyles } from 'react-jss'
import { yellow } from '../../config/colours'

const styles = {
	container: {
		display: 'flex',
		flexDirection: 'column',
		maxWidth: 'min(60rem, 90vw)',
		padding: '0 1rem',
		justifyContent: 'center',
		alignItems: 'center',
		'& > p': {
			width: '100%',
		},
	},
	row: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'center',
	},
	rowCenter: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'center',
	},
	flexCentered: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '4rem',
		flexWrap: 'wrap',
	},
	coinImage: {
		width: '300px',
		padding: '2rem',
	},
	link: {
		color: yellow,
	}
}

export default createUseStyles(styles)
