import { createUseStyles } from 'react-jss'
import { dark_grey, red } from '../../config/colours'
import { weightRegular, weightSemiBold } from '../../config/jss-vars'

const styles = {
	content: {
		width: '100%',
		maxWidth: 'min(50rem, 100%)',
	},
	table: {
		textAlign: 'center',
		borderCollapse: 'collapse',
		width: '100%',
		textTransform: 'uppercase',
		fontWeight: weightRegular,
		'& th': {
			fontWeight: weightSemiBold,
			fontSize: '1.2rem',
		},
		'& td, th': {
			border: `1px solid ${dark_grey}`,
			padding: '0.5rem 0rem',
		},
		'& tr:first-child th': {
			borderTop: '0',
		},
		'& tr td:first-child, tr th:first-child': {
			borderLeft: '0',
		},
		'& tr:last-child td': {
			borderBottom: '0',
		},
		'& tr td:last-child, tr th:last-child': {
			borderRight: '0',
		},
	},
	red: {
		color: red,
	},
	small: {
		fontSize: '0.7rem',
		margin: '0',
	},
}

export default createUseStyles(styles)
