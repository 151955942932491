import { createUseStyles } from 'react-jss'
import { white } from '../../config/colours'

const styles = {
	spacedOut: {
		display: 'flex',
		maxWidth: 'min(50rem, 100%)',
		padding: '0.5rem',
		alignItems: 'center',
		margin: '2rem auto',
		'@media (max-width: 768px)': {
			margin: 0,
		},
	},
	noPadding: {
		display: 'flex',
		maxWidth: '25rem',
		alignItems: 'center',
		margin: '0',
		padding: '0',
		fontSize: '0.8rem',
		height: '100%',
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
		padding: '1rem',
	},
	title: {
		marginTop: 0,
		marginBottom: '0.5rem',
	},
	text: {
		color: white,
		fontSize: '0.9rem',
	},
	button: {
		margin: '1em 0.5em 0',
	},
	small: {
		width: '50px',
		'@media (max-width: 768px)': {
			alignSelf: 'flex-start',
			marginTop: '1rem',
		},
	},
	medium: {
		width: '65px',
		'@media (max-width: 768px)': {
			alignSelf: 'flex-start',
			width: '50px',
			marginTop: '1rem',
		},
	},
	large: {
		width: '100px',
		'@media (max-width: 768px)': {
			alignSelf: 'flex-start',
			width: '50px',
			marginTop: '1rem',
		},
	},
	'@media (max-width: 768px)': {
		spacedOut: {
			transform: 'translateX(0) !important',
		},
		noPadding: {
			transform: 'translateX(0) !important',
		},
	},
}

export default createUseStyles(styles)
