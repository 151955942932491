import PageWrapper, {
	SectionRef,
} from '../../components/PageWrapper/PageWrapper'
import useStyles from './Home.styles'
import {
	COIN_TITLE,
	ECOSYSTEM_BULLETS,
	ECOSYSTEM_DESCRIPTION,
	ECOSYSTEM_TITLE,
	FAQ_TITLE,
	HERO_BUTTON_TEXT,
	HERO_DESCRIPTION,
	HERO_TITLE,
	MINING_TITLE,
	PASS_TITLE,
	PREMINT_LINK,
	STAKING_TITLE,
	TEAM_MEMBER_TITLE,
} from '../../config/content'
import Button from '../../components/Button/Button'
import { useRef } from 'react'
import Team from '../../components/Team/Team'
import More from '../../components/More/More'
import BulletPoint from '../../components/BulletPoint/BulletPoint'
import Heading from '../../components/Heading/Heading'
import InvestorPass from '../../components/InvestorPass/InvestorPass'
import Mining from '../../components/Mining/Mining'
import CallToAction from '../../components/CallToAction/CallToAction'
import Animate from '../../components/Animate/Animate'
import EcoSystem from '../../components/EcoSystem/EcoSystem'
import CoinSection from '../../components/CoinSection/CoinSection'
import StakingSection from '../../components/StakingSection/StakingSection'
import FAQSection from '../../components/FAQSection/FAQSection'
import { useMediaQuery } from 'react-responsive'

const Home: React.FC = () => {
	const classes = useStyles()
	const isMobile = useMediaQuery({ query: '(max-width: 1024px)' })

	// Section references for scroll effects on header
	const homeRef = useRef<HTMLElement>(null)
	const passRef = useRef<HTMLElement>(null)
	const coinRef = useRef<HTMLElement>(null)
	const miningRef = useRef<HTMLElement>(null)
	const stakingRef = useRef<HTMLElement>(null)
	const teamRef = useRef<HTMLElement>(null)
	const faqRef = useRef<HTMLElement>(null)
	const sectionRefs: SectionRef[] = [
		{
			ref: homeRef,
			id: 'top',
		},
		{
			ref: passRef,
			id: 'pass',
		},
		{
			ref: coinRef,
			id: 'coin',
		},
		{
			ref: miningRef,
			id: 'mining',
		},
		{
			ref: stakingRef,
			id: 'staking',
		},
		{
			ref: faqRef,
			id: 'faq',
		},
		{
			ref: teamRef,
			id: 'team',
		},
	]

	return (
		<PageWrapper className="default" sectionRefs={sectionRefs}>
			<div className={classes.page}>
				<section className={classes.hero} ref={homeRef}>
					<iframe
						src="https://player.vimeo.com/video/744361481?h=17ddaf0d02&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;autoplay=1&amp;loop=1&amp;background=1"
						frameBorder="0"
						allow="autoplay; fullscreen; picture-in-picture"
						allowFullScreen
						className={classes.video}
						title="Copium Coin"
					></iframe>
					<div className={classes.alignedRow}>
						{!isMobile && (
							<img className={classes.primaryLogo} src="img/copium_logo.png" />
						)}
						<div className={classes.box}>
							<h1>{isMobile ? 'Welcome to the Future of Web3' : HERO_TITLE}</h1>
							{!isMobile && (
								<>
									<p>{HERO_DESCRIPTION}</p>
									<Button
										aria-label={HERO_BUTTON_TEXT}
										className="primary"
										onClick={() => (window.location.href = PREMINT_LINK)}
										text={HERO_BUTTON_TEXT}
									/>
								</>
							)}
						</div>
					</div>
				</section>

				<section className={classes.section}>
					<Heading color="red">{ECOSYSTEM_TITLE}</Heading>
					<EcoSystem />

					<Animate>
						<p className={classes.largeText}>{ECOSYSTEM_DESCRIPTION}</p>
					</Animate>
					<More color="red" />
					<Heading color="red">Project Overview</Heading>

					{ECOSYSTEM_BULLETS.map((bullet, index) => (
						<Animate key={bullet.title}>
							<BulletPoint
								title={`Stage ${index + 1} - ${bullet.title}`}
								text={bullet.text}
								offset={index + 1}
								color="red"
							/>
						</Animate>
					))}
					<Animate>
						<CallToAction
							title="Get a Guaranteed Mint Spot"
							text="The first 2000 people to register via the website portal prior to auction start date will receive a guaranteed mint spot at a lower, fixed price of 3 ETH."
							color="red"
							button={{
								text: 'Go to Premint',
								onClick: () => window.open(PREMINT_LINK, '_blank'),
								className: 'redInverted',
							}}
						/>
					</Animate>
				</section>

				<section id="pass" className={classes.section} ref={passRef}>
					<Heading color="red">{PASS_TITLE}</Heading>
					<InvestorPass />
				</section>

				<section id="coin" className={classes.section} ref={coinRef}>
					<Heading color="yellow">{COIN_TITLE}</Heading>
					<CoinSection />
				</section>

				<section id="mining" className={classes.section} ref={miningRef}>
					<Heading color="blue">{MINING_TITLE}</Heading>
					<More color="blue" />
					<Mining />
				</section>

				<section id="staking" className={classes.section} ref={stakingRef}>
					<Heading color="yellow">{STAKING_TITLE}</Heading>
					<StakingSection />
				</section>

				<section id="faq" className={classes.section} ref={faqRef}>
					<Heading color="red">{FAQ_TITLE}</Heading>
					<FAQSection />
				</section>

				<section id="team" className={classes.section} ref={teamRef}>
					<Heading color="red">{TEAM_MEMBER_TITLE}</Heading>
					<Team />
				</section>
			</div>
		</PageWrapper>
	)
}

export default Home
