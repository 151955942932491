import { createUseStyles } from 'react-jss'
import {
	text_shadow,
	twitter_blue,
	white,
} from '../../config/colours'
import { headingFontFamily, weightSemiBold } from '../../config/jss-vars'

const styles = {
	teamMember: {
		color: white,
		margin: '0.5rem 1rem',
		padding: '0.5rem 2rem',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		width: '180px',
		height: '198px',
		margin: '0 auto 1em',
		boxSizing: 'border-box',
		objectFit: 'cover',
		'-webkit-clip-path':'polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%)',
		clipPath: 'polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%)',
	},
	memberName: {
		fontWeight: weightSemiBold,
		textTransform: 'uppercase',
		fontFamily: headingFontFamily,
		textShadow: text_shadow,
		fontSize: '1.2rem',
		margin: 0,
	},
	twitterLink: {
		backgroundColor: twitter_blue,
		borderRadius: '0.5rem',
		color: white,
		padding: '0.5rem 1rem',
		textDecoration: 'none',
	},
	description: {
		textAlign: 'justify',
	},
	'@media (max-width: 568px)': {
		teamMember: {
			flexDirection: 'column',
		},
		memberName: {
			textAlign: 'center',
		},
	},
}

export default createUseStyles(styles)
