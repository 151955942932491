import { createUseStyles } from 'react-jss'
import { white, yellow } from '../../config/colours'
import { borderRadius } from '../../config/jss-vars'

const styles = {
	container: {
		display: 'grid',
		gridTemplateColumns: 'repeat(4, 1fr)',
		color: white,
		textAlign: 'center',
		gap: '1em',
		padding: '1em',
		border: `1px solid ${yellow}`,
		borderRadius,
	},
	item: {
		display: 'flex',
		flexDirection: 'column',
		gap: '1em',
	},
	number: {
		fontSize: '1.5em',
		margin: 0,
	},
	text: {
		margin: 0,
	},
}

export default createUseStyles(styles)
