import { createUseStyles } from 'react-jss'
import { red } from '../../config/colours'

const styles = {
	container: {
		display: 'flex',
		flexDirection: 'column',
		maxWidth: '60rem',
		padding: '0 1rem',
		justifyContent: 'center',
		alignItems: 'center',
		'& > p': {
			width: '100%',
		},
	},
	rowCenter: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'center',
	},
	rowTextCenter: {
		composes: '$rowCenter',
		textAlign: 'center',
	},
	video: {
		width: '100%',
		height: '100%',
		maxWidth: 'min(80vh, 90vw)',
		maxHeight: 'min(80vh, 90vw)',
		paddingTop: '3rem',
		paddingBottom: '3rem',
	},
	footer: {
		backgroundColor: red,
		textAlign: 'center',
		fontSize: '1.2rem',
		width: '100%',
		marginTop: '1em',
		padding: '2rem 0',
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		gap: '1rem',
		'& p': {
			margin: '0',
		},
		marginTop: '-1rem',
	},
	column: {
		width: '49%',
		maxWidth: '60em',
		'@media (max-width: 768px)': {
			width: '100vw',
		},
	},
}

export default createUseStyles(styles)
