import { createUseStyles } from 'react-jss'
import { black, blue, red, white, yellow } from '../../config/colours'
import { borderRadius, weightSemiBold } from '../../config/jss-vars'

const styles = {
	container: {
		marginTop: '2em',
		height: '2em',
		width: '100%',
		backgroundColor: white,
		position: 'relative',
		borderRadius,
	},
	fill: {
		height: '100%',
		borderRadius: 'inherit',
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
	red: {
		composes: '$fill',
		background: red,
	},
	blue: {
		composes: '$fill',
		background: blue,
	},
	yellow: {
		composes: '$fill',
		background: yellow,
	},
	label: {
		textAlign: 'right',
		color: white,
		fontSize: '0.8rem',
		fontWeight: weightSemiBold,
	},
	indicator: {
		borderRight: `3px solid ${red}`,
		position: 'absolute',
		height: '7rem',
		marginTop: '-2.5rem',
		fontSize: '0.8rem',
		zIndex: '500',
		textAlign: 'right',
		paddingRight: '-2rem',
		'& span': {
			color: black,
			fontWeight: weightSemiBold,
			fontSize: '1rem',
			padding: '0 0.5rem',
			backgroundColor: red,
			borderRadius: `${borderRadius} 0 0 ${borderRadius}`,
		},
	},
	firstIndicator: {
		composes: '$indicator',
		right: '50%',
	},
	secondIndicator: {
		composes: '$indicator',
		right: '1%',
	},
}

export default createUseStyles(styles)
