import useStyles from './CountDown.styles'
import { useEffect, useState } from 'react'

interface Props {
	when: number // Unix timestamp
}

interface TimeDiff {
	days: number
	hours: number
	minutes: number
	seconds: number
}

const CountDown: React.FC<Props> = ({ when }) => {
	const classes = useStyles()

	const [diff, setDiff] = useState<TimeDiff>()

	const tick = () => {
		let diff = Math.floor((when - Date.now()) / 1000)
		const seconds = diff % 60
		diff = Math.floor(diff / 60)
		const minutes = diff % 60
		diff = Math.floor(diff / 60)
		const hours = diff % 24
		const days = Math.floor(diff / 24)
		setDiff({
			days,
			hours,
			minutes,
			seconds,
		})
	}

	useEffect(() => {
		tick()
		const timer = setInterval(tick, 1000)

		return () => clearInterval(timer)
	}, [])

	const zeroPad = (num?: number): string => {
		return num !== undefined ? (num < 10 ? `0${num}` : `${num}`) : '...'
	}

	return (
		<div className={classes.container}>
			<div className={classes.item}>
				<h4 className={classes.number}>{zeroPad(diff?.days)}</h4>
				<p className={classes.text}>Days</p>
			</div>
			<div className={classes.item}>
				<h4 className={classes.number}>{zeroPad(diff?.hours)}</h4>
				<p className={classes.text}>Hours</p>
			</div>
			<div className={classes.item}>
				<h4 className={classes.number}>{zeroPad(diff?.minutes)}</h4>
				<p className={classes.text}>Minutes</p>
			</div>
			<div className={classes.item}>
				<h4 className={classes.number}>{zeroPad(diff?.seconds)}</h4>
				<p className={classes.text}>Seconds</p>
			</div>
		</div>
	)
}

export default CountDown
