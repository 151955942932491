import { useState } from 'react'
import useStyles from './Accordion.styles'

interface Props {
	title: string
	expandedText: string
}

const Accordion: React.FC<Props> = ({ title, expandedText }) => {
	const classes = useStyles()
	const [open, setOpen] = useState(false)

	return (
		<div className={classes.accordion}>
			<h4 className={open ? classes.titleOpen : classes.title} onClick={() => setOpen(o => !o)}>
				{title}
			</h4>
			<p className={`${classes.description} ${open && classes.open}`}>{expandedText}</p>
		</div>
	)
}

export default Accordion
