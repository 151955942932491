import useStyles from './ProgressBar.styles'

type BarColor = 'yellow' | 'red' | 'blue'

interface Props {
	current: number
	max: number
	color: BarColor
	showIndicators: boolean
}

const ProgressBar: React.FC<Props> = ({
	current,
	max,
	color,
	showIndicators,
}) => {
	const classes = useStyles()

	let width = (current * 100) / max
	if (width < 20) {
		// Min display 20%
		width = 20
	}

	return (
		<>
			<div className={classes.container}>
				{showIndicators && (
					<>
						<div className={classes.firstIndicator}>
							<span>Soft Cap (2.5%)</span>
						</div>
						<div className={classes.secondIndicator}>
							<span>Hard Cap (5%)</span>
						</div>
					</>
				)}
				<div
					className={`${classes.fill} ${classes[color]}`}
					style={{ width: `${width}%` }}
				></div>
			</div>
			<span className={classes.label}>
				{current} / {max}
			</span>
		</>
	)
}

export default ProgressBar
