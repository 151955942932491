/**
 * Colours
 */
export const black = '#101518'
export const dark_grey = '#262631'
export const light_grey = '#575560'
export const white = '#FFF'
export const red = '#A5282E'
export const yellow = '#E1B867'
export const blue = red

// Page background
export const page_background = black
export const page_text = white

// Connect and mint buttons
export const button_background = red
export const button_text = white

// Team section
export const team_background = black
export const team_text = white
export const team_member_background = black
export const team_member_text = white

// Spinner colour
export const spinnerColour = white

// Pop up theme ('light' or 'dark' mode)
export const TOAST_THEME = 'dark'

// Constants
export const off_black = 'rgba(0,0,0,0.25)'
export const off_white = 'rgba(255,255,255,0.85)'
export const text_shadow = '3px 3px 5px #00000044'
export const box_shadow = '0 0 10px 5px #00000022'

// Social
export const twitter_blue = '#548FD2'
