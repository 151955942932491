import { FC, useContext, useEffect, useState } from 'react'
import Button from '../../components/Button/Button'
import { Web3Context } from '../../context/Web3/Web3Context'
import useStyles from './Minting.styles'
import { ContractContext } from '../../context/Web3/ContractContext'
import {
	MINT_BTN_TEXT,
	MINT_FAIL_GENERIC,
	MINT_FAIL_POOR,
	MINT_NOSALE_SUBTEXT,
	MINT_NOSALE_TITLE,
	MINT_NOT_ALLOWLISTED,
	MINT_PAGE_TITLE,
	MINT_SOLDOUT_SUBTEXT,
	MINT_SOLDOUT_TITLE,
	MINT_SUCCESS,
	OPENSEA_LINK,
	TX_PENDING,
	WEBSITE_OUTDATED,
} from '../../config/content'
import { BigNumber, ContractTransaction, utils } from 'ethers'
import Spinner from '../Spinner/Spinner'
import { ALLOWLIST } from '../../config/allowlist'
import { generateTree, getLeaf, getProof } from '../../utils/merkle'
import { toast } from 'react-toastify'
import Select from '../Select/Select'
import { MAX_SUPPLY } from '../../config/chain'
import OpenseaLogo from '../Logo/OpenseaLogo'
import Heading from '../Heading/Heading'
import ProgressBar from '../ProgressBar/ProgressBar'
import CountDown from '../CountDown/CountDown'

const Minting: FC = () => {
	const { web3Provider } = useContext(Web3Context)
	const { passContract } = useContext(ContractContext)
	const classes = useStyles()
	const [loading, setLoading] = useState(true)
	const [totalSupply, setTotalSupply] = useState<number | null>(null)
	const [auctionActive, setAuctionActive] = useState(false)
	const [currentPrice, setCurrentPrice] = useState<BigNumber | null>(null)
	const [auctionStart, setAuctionStart] = useState<number | null>(null)
	const [allowance, setAllowance] = useState(0)
	const [txPending, setTxPending] = useState(false)

	useEffect(() => {
		checkStatus()
	}, [web3Provider, passContract])

	useEffect(() => {
		const interval = setInterval(() => {
			checkStatus()
		}, 1000 * 10 * 60) // 10 minutes
		return () => clearInterval(interval)
	}, [])

	const checkStatus = async () => {
		if (!web3Provider || !passContract) {
			return
		}
		const signer = web3Provider.getSigner()
		const _totalSupply = (await passContract.totalSupply()).toNumber()
		setTotalSupply(_totalSupply)
		if (_totalSupply < MAX_SUPPLY) {
			// Not sold out
			const startTime = (await passContract.auction()).startTime.toNumber()
			const now = (await web3Provider.getBlock('latest')).timestamp
			const auctionActive = startTime !== 0 && startTime <= now
			setAuctionActive(auctionActive)
			setAuctionStart(startTime)
			if (!auctionActive) {
				const addr = await signer.getAddress()
				const listed = ALLOWLIST.includes(addr.toLowerCase())
				let isEligible = true
				if (!listed) {
					// Presale, not on list
					setAllowance(0)
					isEligible = false
					toast.warn(MINT_NOT_ALLOWLISTED, { autoClose: false })
				} else if (
					generateTree(ALLOWLIST).root !== (await passContract.merkleRoot())
				) {
					setAllowance(0)
					isEligible = false
					toast.warn(WEBSITE_OUTDATED, { autoClose: false })
				}
				if (isEligible) {
					setAllowance(1)
					setCurrentPrice(await passContract.presalePrice())
				}
			} else {
				// public dutch auction
				setCurrentPrice(await passContract.getPrice(now))
				setAllowance(10)
			}
		}
		setLoading(false)
	}

	const doMint = async () => {
		setTxPending(true)
		const qty =
			(document.getElementById('qty') as HTMLSelectElement)?.selectedIndex + 1
		if (!web3Provider || !passContract || !qty) {
			return
		}
		const signer = web3Provider.getSigner()
		const addr = (await signer.getAddress()).toLowerCase()
		try {
			let tx: ContractTransaction
			if (!auctionActive) {
				// Presale
				const { merkleTree } = generateTree(ALLOWLIST)
				const leaf = getLeaf(addr)
				const proof = getProof(merkleTree, leaf)
				tx = await passContract.mintPresale(addr, proof, {
					value: BigNumber.from(await passContract.presalePrice()),
				})
			} else {
				// public dutch auction
				const now = (await web3Provider.getBlock('latest')).timestamp
				const price = await passContract.getPrice(now)
				if (price !== currentPrice) {
					setCurrentPrice(price)
				}

				tx = await passContract.mint(addr, qty, {
					value: price.mul(qty),
				})
			}
			setTxPending(true)
			await tx.wait()
			toast.success(MINT_SUCCESS)
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (err: any) {
			if (err?.error?.code === -32000) {
				toast.error(MINT_FAIL_POOR)
			} else {
				toast.error(MINT_FAIL_GENERIC)
			}
			console.error(err)
		} finally {
			setTxPending(false)
		}
	}

	return (
		<div className={classes.page}>
			{loading ? (
				<Spinner />
			) : (totalSupply ?? 0) >= MAX_SUPPLY ? (
				<>
					<Heading color="red">{MINT_SOLDOUT_TITLE}</Heading>
					<p>{MINT_SOLDOUT_SUBTEXT}</p>
					<a href={OPENSEA_LINK} target="_blank">
						<OpenseaLogo />
					</a>
				</>
			) : allowance === 0 ? (
				<>
					<Heading color="red">{MINT_NOSALE_TITLE}</Heading>
					{auctionStart && auctionStart * 1000 > Date.now() ? (
						<CountDown when={auctionStart * 1000} />
					) : (
						<p>{MINT_NOSALE_SUBTEXT}</p>
					)}
				</>
			) : (
				<>
					<Heading color="red">{MINT_PAGE_TITLE}</Heading>
					<div className={classes.barContainer}>
						<ProgressBar
							current={totalSupply === null ? 0 : totalSupply}
							max={MAX_SUPPLY}
							color="red"
							showIndicators={false}
						/>
					</div>
					{currentPrice && (
						<p>
							Only {(+utils.formatEther(currentPrice)).toFixed(10)} ETH each
						</p>
					)}
					<div className={classes.mint}>
						<Select
							id="qty"
							defaultValue={1}
							required
							disabled={allowance === 0}
						>
							{Array.from(Array(allowance).keys()).map(i => (
								<option key={i} value={i + 1}>
									{i + 1}
								</option>
							))}
						</Select>
						<Button
							className="primary"
							onClick={doMint}
							disabled={txPending || allowance === 0}
							text={txPending ? TX_PENDING : MINT_BTN_TEXT}
						/>
					</div>
				</>
			)}
		</div>
	)
}

export default Minting
