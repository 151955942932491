import useStyles, { ClassNames } from './Button.styles'
import ReactGA from 'react-ga'

export interface ButtonProps {
	className?: ClassNames
	text: string
  onClick: () => void
  disabled?: boolean
}

const Button: React.FC<ButtonProps> = ({text, onClick, className, disabled}) => {
	const classes = useStyles()

	const trackedClick = () => {
		ReactGA.event({
			category: 'Button',
			action: 'Click',
			label: text,
		})
		onClick()
	}

	return (
		<button
			className={classes[className || 'button']}
			onClick={trackedClick}
			disabled={disabled}
		>
			{text}
		</button>
	)
}

export default Button
