/**
 * Fonts
 */

export const standardFontFamily =
	'"Duru Sans", "Helvetica Neue", Helvetica, Arial, sans-serif'
export const headingFontFamily = '"aviano-future", "Duru Sans", "Helvetica Neue", Helvetica, Arial, sans-serif'
export const weightSemiBold = 600
export const weightRegular = 400
export const weightMedium = 500
export const headingLetterSpacing = '0.2em'

/**
 * Corners
 */
export const borderRadius = '5px'

/**
 * Google Analytics
 */
export const TRACKING_ID = 'UA-237232302-1'
