export const getICOId = (chainId: number): string =>
	chainId === 4
		? '0x641077c77f1b21C4A79CF32A967bfb2749204F9A'
		: '0x1577C729AF4E9B97aE07dEef840357ceA8E4A637'
export const getPassId = (chainId: number): string =>
	chainId === 4 ? '0x1a7D7d89EEA4557B3271CC5697d837f966f62E4E' : ''
export const MAX_SUPPLY = 10000

export const INFURA_ID = '288d3145859e45dca9d1c701fce7113e'
export const VALID_CHAIN_IDS = [1, 4]
