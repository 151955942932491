import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import useStyles, { ClassNames } from './PageWrapper.styles'

export interface SectionRef {
	id: string
	ref: React.RefObject<HTMLElement>
}

interface Props {
	className?: ClassNames
	children?: React.ReactNode
	hasHeader?: boolean
	hasFooter?: boolean
	sectionRefs?: SectionRef[]
}

const PageWrapper: React.FC<Props> = ({
	children,
	className = 'default',
	hasHeader = true,
	hasFooter = true,
	sectionRefs,
}) => {
	const classes = useStyles()

	return (
		<div id="top" className={classes[className]}>
			{hasHeader && <Header sectionRefs={sectionRefs} />}
			<main className={classes.main}>{children}</main>
			{hasFooter && <Footer />}
		</div>
	)
}

export default PageWrapper
