import { createUseStyles } from 'react-jss'
import { yellow } from '../../config/colours'

const styles = {
	content: {
		maxWidth: '50rem',
		padding: '2rem',
		'& p': {
			textAlign: 'center',
		},
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		gap: '1rem',
		'& p': {
			margin: '1rem 0',
		},
		marginTop: '-1rem',
	},
	column: {
		width: '49%',
		maxWidth: '60em',
		'@media (max-width: 768px)': {
			width: '100vw',
		},
	},
	smallTitle: {
		marginTop: '2em',
		textAlign: 'center',
		color: yellow,
	},
	spacer: {
		margin: '3em',
	},
}

export default createUseStyles(styles)
