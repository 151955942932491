import { createUseStyles } from 'react-jss'
import { yellow } from '../../config/colours'
const styles = {
	container: {
		width: '100%',
		textAlign: 'center',
	},
	yellow: {
		cursor: 'pointer',
		textDecoration: 'underline',
		fontStyle: 'italic',
		color: yellow,
	}
}

export default createUseStyles(styles)
