import useStyles from './Card.styles'

export interface CardProps {
	image: string
	title: string
	padded?: boolean
}

const Card: React.FC<CardProps> = ({ image, title, padded = false }) => {
	const classes = useStyles()
	return (
		<div className={classes.container}>
			<div className={classes.text}>{title}</div>
			<img className={padded ? classes.imagePadded : classes.image} src={image} />
		</div>
	)
}

export default Card
