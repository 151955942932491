import { createUseStyles } from 'react-jss'
import { black, off_black, white } from '../../config/colours'
import { headingFontFamily } from '../../config/jss-vars'

const styles = {
	accordion: {
		margin: '1em',
		padding: '1.5rem 4rem',
		background: white,
		color: black,
		boxShadow: `0em 0.15em 0.65em 0em ${off_black}`,
		'@media (max-width: 768px)': {
			padding: '1.5rem',
		},
	},
	title: {
		fontSize: '1.5em',
		margin: '0',
		fontFamily: headingFontFamily,
		'&::before': {
			content: '"+"',
			paddingRight: '0.5em',
			display: 'inline-block',
			width: '0.5em',
		},
		cursor: 'pointer',
	},
	titleOpen: {
		composes: '$title',
		'&::before': {
			content: '"-"',
		},
	},
	description: {
		position: 'relative',
		overflow: 'hidden',
		opacity: 0,
		maxHeight: 0,
		transform: 'translate(0, 0)',
		margin: 0,
		zIndex: '2',
		transition: 'all 0.25s ease-in-out',
	},
	open: {
		opacity: 1,
		maxHeight: '100vh',
		marginTop: '14px',
	},
}

export default createUseStyles(styles)
