import { useState } from 'react'
import useStyles from './ClickToReveal.styles'

interface Props {
	column1Text: string
	children: React.ReactNode | React.ReactNode[]
}

const ClickToReveal: React.FC<Props> = ({ children, column1Text }) => {
	const classes = useStyles()
	const [isRevealed, setIsRevealed] = useState(false)

	return (
		<>
			{isRevealed ? (
				children
			) : (
				<tr
					className={classes.container}
					onClick={() => {
						setIsRevealed(true)
					}}
				>
					<td>{column1Text}</td>
					<td className={classes.yellow}>Click to Reveal</td>
					<td className={classes.yellow}>Click to Reveal</td>
				</tr>
			)}
		</>
	)
}

export default ClickToReveal
