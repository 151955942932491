import { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import { PREMINT_LINK, TELEGRAM_LINK, TWITTER_LINK } from '../../config/content'
import { HomeRoute, ICORoute } from '../../pages/routes'
import Button from '../Button/Button'
import CopiumLogo from '../Logo/CopiumLogo'
import { SectionRef } from '../PageWrapper/PageWrapper'
import useStyles from './Header.styles'
import Burger from './Burger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTelegram, faTwitter } from '@fortawesome/free-brands-svg-icons'

interface Props {
	sectionRefs?: SectionRef[]
}

const Header: React.FC<Props> = ({ sectionRefs }) => {
	const classes = useStyles()
	const isMobile = useMediaQuery({ query: '(max-width: 1024px)' })
	const isDesktop = !isMobile
	const [activeIndex, setActiveIndex] = useState(0)

	useEffect(() => {
		const handleScroll = () => {
			if (sectionRefs) {
				for (let i = 0; i < sectionRefs.length; i++) {
					if (
						window.scrollY + window.innerHeight / 4 <=
						(sectionRefs[i].ref.current?.offsetTop || 0)
					) {
						setActiveIndex(i === 0 ? 0 : i - 1)
						return
					}
				}
				setActiveIndex(sectionRefs.length - 1)
			}
		}
		document.addEventListener('scroll', handleScroll)
		return () => {
			document.removeEventListener('scroll', handleScroll)
		}
	}, [sectionRefs])

	if (isMobile) {
		return <Burger />
	}

	return (
		<header className={classes.header}>
			<nav className={classes.nav}>
				<HashLink smooth to={`${HomeRoute.path}#top`}>
					<CopiumLogo />
				</HashLink>
				<Link to={ICORoute.path} className={classes.link}>
					Presale ICO
				</Link>
				<HashLink
					smooth
					to={`${HomeRoute.path}#pass`}
					className={activeIndex === 1 ? classes.linkSelected : classes.link}
				>
					Investor Pass
				</HashLink>
				<HashLink
					smooth
					to={`${HomeRoute.path}#coin`}
					className={activeIndex === 2 ? classes.linkSelected : classes.link}
				>
					Copium Coin
				</HashLink>
				<HashLink
					smooth
					to={`${HomeRoute.path}#mining`}
					className={activeIndex === 3 ? classes.linkSelected : classes.link}
				>
					Copium Mining
				</HashLink>
				{isDesktop && (
					<>
						<HashLink
							smooth
							to={`${HomeRoute.path}#staking`}
							className={
								activeIndex === 4 ? classes.linkSelected : classes.link
							}
						>
							Staking
						</HashLink>
						<HashLink
							smooth
							to={`${HomeRoute.path}#faq`}
							className={
								activeIndex === 5 ? classes.linkSelected : classes.link
							}
						>
							FAQ
						</HashLink>
						<div className={classes.socials}>
							<a href={TELEGRAM_LINK} target="_blank">
								<FontAwesomeIcon icon={faTelegram} />
							</a>
							<a href={TWITTER_LINK} target="_blank">
								<FontAwesomeIcon icon={faTwitter} />
							</a>
						</div>
					</>
				)}
				<Button
					text="Register Now"
					onClick={() => window.open(PREMINT_LINK, '_blank')}
					className="red"
				/>
			</nav>
		</header>
	)
}

export default Header
