import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import { HomeRoute, ICORoute } from '../../pages/routes'
import { slide as Menu } from 'react-burger-menu'
import useStyles from './Header.styles'
import { styles } from './Burger.styles'
import { TELEGRAM_LINK, TWITTER_LINK } from '../../config/content'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTelegram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import CopiumLogo from '../Logo/CopiumLogo'

const Burger: React.FC = () => {
	const classes = useStyles()
	return (
		<>
			<header className={classes.burger}>
				<div className={classes.title}>
					<CopiumLogo />
					<h2>Copium Protocol</h2>
				</div>
				<Menu right styles={styles}>
					<HashLink smooth to={`${HomeRoute.path}#top`}>
						<CopiumLogo />
					</HashLink>
					<Link to={ICORoute.path} className={classes.link}>
						Presale ICO
					</Link>
					<HashLink
						smooth
						to={`${HomeRoute.path}#pass`}
						className={classes.link}
					>
						Investor Pass
					</HashLink>
					<HashLink
						smooth
						to={`${HomeRoute.path}#coin`}
						className={classes.link}
					>
						Copium Coin
					</HashLink>
					<HashLink
						smooth
						to={`${HomeRoute.path}#mining`}
						className={classes.link}
					>
						Copium Mining
					</HashLink>
					<HashLink
						smooth
						to={`${HomeRoute.path}#staking`}
						className={classes.link}
					>
						Staking
					</HashLink>
					<HashLink
						smooth
						to={`${HomeRoute.path}#faq`}
						className={classes.link}
					>
						FAQ
					</HashLink>
					<HashLink
						smooth
						to={`${HomeRoute.path}#team`}
						className={classes.link}
					>
						Team
					</HashLink>
					<div className={classes.socials}>
						<a href={TELEGRAM_LINK} target="_blank">
							<FontAwesomeIcon icon={faTelegram} />
						</a>
						<a href={TWITTER_LINK} target="_blank">
							<FontAwesomeIcon icon={faTwitter} />
						</a>
					</div>
				</Menu>
			</header>
		</>
	)
}

export default Burger
