import { createUseStyles } from 'react-jss'
import { black, blue, red, white, yellow } from '../../config/colours'
import { borderRadius } from '../../config/jss-vars'

const styles = {
	box: {
		display: 'flex',
		flexDirection: 'column',
		gap: '1.5em',
		minWidth: 'min(60em, 100vw)',
		maxWidth: 'min(60em, 100vw)',
		padding: '2em',
		boxSizing: 'border-box',
		borderRadius,
	},
	red: {
		composes: '$box',
		background: red,
		color: white,
	},
	blue: {
		composes: '$box',
		background: blue,
		color: white,
	},
	yellow: {
		composes: '$box',
		background: yellow,
		color: black,
	},
	textSection: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'flex-start',
		gap: '1em',
		'& > *': {
			margin: 0,
		}
	},
	button: {
		whiteSpace: 'nowrap',
	},
}

export default createUseStyles(styles)
