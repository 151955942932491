import { FC, useContext, useEffect, useState } from 'react'
import Button from '../Button/Button'
import { Web3Context } from '../../context/Web3/Web3Context'
import useStyles from './ICOBox.styles'
import { ContractContext } from '../../context/Web3/ContractContext'
import {
	ICO_SALEOFF,
	ICO_SOLDOUT,
	MINT_FAIL_GENERIC,
	MINT_FAIL_POOR,
	MINT_SUCCESS,
	TX_PENDING,
} from '../../config/content'
import { BigNumber, ContractTransaction, ethers } from 'ethers'
import Spinner from '../Spinner/Spinner'
import { toast } from 'react-toastify'
import Input from '../Input/Input'
import Heading from '../Heading/Heading'
import ProgressBar from '../ProgressBar/ProgressBar'
import Stat from '../Stat/Stat'
import { formatEthers, formatNumber } from '../../utils/format'
import CountDown from '../CountDown/CountDown'
import ConnectButton from '../Button/ConnectButton'

interface ICOInfo {
	enabled: boolean
	hardCap: number
	purchased: number
	pricePer: BigNumber
}

const PLACEHOLDER_INFO: ICOInfo = {
	enabled: true,
	hardCap: 50000000,
	purchased: 25000,
	pricePer: BigNumber.from(0x311cdad16000),
}

const ICOBox: FC = () => {
	const { web3Provider } = useContext(Web3Context)
	const { icoContract } = useContext(ContractContext)
	const classes = useStyles()
	const [loading, setLoading] = useState(true)
	const [info, setInfo] = useState<ICOInfo>(PLACEHOLDER_INFO)
	const [ethAmount, setEthAmount] = useState('')
	const [txPending, setTxPending] = useState(false)

	const calculateEthAmount = (qty?: number, _info?: ICOInfo) => {
		if (!qty) {
			qty = Number(
				(document.getElementById('qty') as HTMLInputElement)?.value || 1000,
			)
		}
		setEthAmount(formatEthers((_info || info).pricePer.mul(qty), 3))
	}

	const checkStatus = async () => {
		if (!web3Provider || !icoContract) {
			setLoading(false)
			return
		}
		setLoading(true)
		const [
			enabled,
			hardCap,
			purchased,
			pricePer,
		]: BigNumber[] = await icoContract.icoView()
		const _info = {
			enabled: enabled.eq(ethers.constants.One),
			hardCap: hardCap.toNumber(),
			purchased: purchased.toNumber(),
			pricePer,
		}
		setInfo(_info)
		calculateEthAmount(undefined, _info)
		setLoading(false)
	}

	useEffect(() => {
		checkStatus()
	}, [web3Provider, icoContract])

	const buy = async () => {
		setTxPending(true)
		const qty = (document.getElementById('qty') as HTMLInputElement)?.value
		if (!web3Provider || !icoContract || !qty) {
			return
		}
		try {
			const tx: ContractTransaction = await icoContract.prepurchase(qty, {
				value: info.pricePer.mul(qty),
			})
			await tx.wait()
			toast.success(MINT_SUCCESS)
			checkStatus()
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (err: any) {
			if (err?.error?.code === -32000) {
				toast.error(MINT_FAIL_POOR)
			} else {
				toast.error(MINT_FAIL_GENERIC)
			}
			console.error(err.error)
		} finally {
			setTxPending(false)
		}
	}

	//FIXME Invert
	const presalePrice = `1 ETH = ${formatNumber(
		1 / Number(ethers.utils.formatEther(info.pricePer)),
	)} Copium`

	return (
		<div className={classes.page}>
			{loading ? (
				<Spinner />
			) : info.hardCap === 0 ? (
				<Heading color="yellow">Coming Soon</Heading>
			) : (info.purchased || 0) >= (info.hardCap || 0) ? (
				<Heading color="yellow">{ICO_SOLDOUT}</Heading>
			) : info?.enabled ? (
				<div className={classes.paddedPage}>
					<p className={classes.justified}>
						Connect wallet to display live presale figures, and purchase Copium Coin via presale.
						Once Copium coin presale ico has concluded, purchased tokens will be available 
						to be claimed via the claim page on our website.
					</p>
					<CountDown when={1667775600000} />
					<Heading color="yellow">Buy Copium Coin Now</Heading>
					<div className={classes.remaining}>
						<ProgressBar
							current={info.purchased}
							max={info.hardCap}
							color="yellow"
							showIndicators={true}
						/>
					</div>
					<div className={classes.mint}>
						<Input
							id="qty"
							defaultValue={1000}
							type="number"
							min="1"
							max={info.hardCap - info.purchased}
							onChange={e => {
								calculateEthAmount(Number(e.target.value))
							}}
							required
						/>
						{web3Provider ? (
							<Button
								className="primary"
								onClick={buy}
								disabled={txPending}
								text={txPending ? TX_PENDING : `Purchase for ${ethAmount} ETH`}
							/>
						) : (
							<ConnectButton unconnectedText="Connect for Price" />
						)}
					</div>
				</div>
			) : (
				<Heading color="yellow">{ICO_SALEOFF}</Heading>
			)}
			<div className={classes.stats}>
				<img className={classes.coinImage} src="img/copium_coin.png" />
				<div className={classes.row}>
					<Stat
						title="Total Presale Supply"
						amount="50,000,000"
						animated={false}
						fullWidth={false}
						small={true}
					/>
					<Stat
						title="Presale Price"
						amount={presalePrice}
						animated={false}
						fullWidth={false}
						small={true}
					/>
				</div>
			</div>
			<div className={classes.stats}>
				<p className={classes.textBox}>
					Copium Coin is the Native ERC-20 token that powers the Copium Protocol
					ecosystem. Backed by an established, real world, large scale crypto
					mining facility in NZ. Mining revenue is utilized daily to purchase
					Copium Coin from the open market. Purchased Copium is then sent to
					token burn address, reducing circulating supply, increasing scarcity,
					ensuring Copium remains deflationary.
				</p>
			</div>
		</div>
	)
}

export default ICOBox
