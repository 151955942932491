import { createUseStyles } from 'react-jss'
import { borderRadius, headingFontFamily, weightSemiBold } from '../../config/jss-vars'
import {
	off_black,
	button_text,
	button_background,
	yellow,
	blue,
	red,
	white,
	text_shadow,
} from '../../config/colours'

export type ClassNames = 'primary' | 'link' | 'yellow' | 'blue' | 'red' | 'yellowInverted' | 'blueInverted' | 'redInverted'

const styles = {
	base: {
		cursor: 'pointer',
		fontFamily: headingFontFamily,
		textShadow: text_shadow,
		fontSize: '1.5rem',
		lineHeight: '1rem',
		textAlign: 'center',
		display: 'inline-block',
		outline: 'none',
	},
	button: {
		composes: '$base',
		border: 'none',
		padding: '1rem 2rem',
		borderRadius,
		fontSize: '0.8em',
		letterSpacing: 0,
		textTransform: 'uppercase',
		fontWeight: weightSemiBold,
		boxShadow: `0em 0.15em 0.65em 0em ${off_black}`,
		'&:disabled': {
			cursor: 'default',
			opacity: 0.5,
			pointerEvents: 'none',
		},
		'&:hover': {
			opacity: '0.85',
			transition: 'opacity .25s ease-in-out',
		},
	},
	primary: {
		composes: '$button',
		background: button_background,
		color: button_text,
	},
	yellow: {
		composes: '$button',
		background: yellow,
		color: button_text,
	},
	yellowInverted: {
		composes: '$button',
		background: button_text,
		color: yellow,
	},
	blue: {
		composes: '$button',
		background: blue,
		color: button_text,
	},
	blueInverted: {
		composes: '$button',
		background: button_text,
		color: blue,
	},
	red: {
		composes: '$button',
		background: red,
		color: button_text,
	},
	redInverted: {
		composes: '$button',
		background: white,
		color: red,
	},
	// Unused
	link: {
		composes: '$base',
		border: 0,
		cursor: 'pointer',
		display: 'inline-block',
		padding: 0,
		backgroundColor: 'transparent',
		fontWeight: weightSemiBold,
		fontSize: '0.875rem',
		height: '0.875rem',
		lineHeight: '0.875rem',
		letterSpacing: 0.2,
	},
}

export default createUseStyles(styles)
