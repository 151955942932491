import { createUseStyles } from 'react-jss'

const styles = {
	page: {
		minWidth: '100vw',
		maxWidth: '100vw',
		minHeight: '50vh',
		marginBottom: '5em',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		'& h2': {
			fontSize: '2em',
		},
		'& p': {
			maxWidth: '50rem',
			fontSize: '1em',
		},
	},
	paddedPage: {
		composes: '$page',
		gap: '2em',
		marginTop: '3em',
	},
	mint: {
		display: 'grid',
		gridTemplateColumns: '1fr 1fr',
		gap: '2em',
	},
	remaining: {
		marginBottom: '1em',
		width: '50rem',
		maxWidth: '90vw',
		fontSize: '1.3em',
	},
	stats: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	coinImage: {
		width: '200px',
		padding: '1rem',
	},
	textBox: {
		padding: '1em',
		textAlign: 'center',
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		'@media (max-width: 768px)': {
			flexDirection: 'column',
		},
	},
	justified: {
		textAlign: 'justify',
		padding: '15px',
	},
}

export default createUseStyles(styles)
