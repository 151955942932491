import { AnimationOnScroll } from 'react-animation-on-scroll'

interface Props {
	children: React.ReactNode | React.ReactNode[]
}

const Animate: React.FC<Props> = ({ children }) => (
	<AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
		{children}
	</AnimationOnScroll>
)

export default Animate
