import Animate from '../Animate/Animate'
import BulletPoint from '../BulletPoint/BulletPoint'
import More from '../More/More'
import useStyles from './InvestorPass.styles'

const Team: React.FC = () => {
	const classes = useStyles()

	return (
		<div className={classes.container}>
			<div className={classes.rowCenter}>
				<Animate>
					<BulletPoint
						color='red'
						text="10k collection of Investor Pass Nfts on the Ethereum blockchain."
						size="small"
						padding={false}
					/>
				</Animate>
				<Animate>
					<BulletPoint
						color='red'
						text="Collection will be Dutch Auctioned at a starting price of 3.5 ETH on the 7th of November 2022."
						size="small"
						padding={false}
					/>
				</Animate>
				<Animate>
					<BulletPoint
						color='red'
						text="The first 2000 people to pre-register via Premint link on the Copium Protocol website, will get a guaranteed mint spot at a lower fixed price of 3 ETH. Numbers are strictly limited!"
						size="small"
						padding={false}
					/>
				</Animate>
				<Animate>
					<BulletPoint
						color='red'
						text="Investor Pass holders will be eligible for an array of real-world benefits, far in excess of their initial investment in recognition of their early support of Copium Protocol."
						size="small"
						padding={false}
					/>
				</Animate>
			</div>
			<div className={classes.footer}>
				EACH INVESTOR PASS MINTED WILL GO INTO THE DRAW TO WIN 1 OF 2 BITCOIN
				GIVEAWAYS
			</div>
			<div className={classes.video}>
				<div style={{ padding: '100% 0 0 0', position: 'relative' }}>
					<iframe
						src="https://player.vimeo.com/video/739136598?h=8d1f1750dd&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;autoplay=1&amp;loop=1&amp;background=1"
						frameBorder="0"
						allow="autoplay; fullscreen; picture-in-picture"
						allowFullScreen
						style={{
							position: 'absolute',
							top: 0,
							left: 0,
							width: '100%',
							height: '100%',
						}}
						title="Investor Pass"
					></iframe>
				</div>
			</div>
			<div className={classes.rowTextCenter}>
				<Animate>
					<p>
						Funds from the sale of the Copium Protocol Investor Pass, will be
						used to purchase wholesale quantities of the latest, and most power
						efficient generation of miners from our suppliers, who we have
						established relationships with, in order to secure the lowest prices
						and preferential supply. Funds will also be used to upgrade
						infrastructure and capacity at the existing Copium Mining facility.
					</p>
				</Animate>
				<More color="red" />
				<Animate>
					<p>
						Investor Pass Holders will be eligible for a one off, exclusive
						Copium Coin airdrop. (Each Investor Pass holder will receive a
						quantity of 10,000 Copium Coins via a gas only transaction)
					</p>
				</Animate>
				<More color="red" />
				<Animate>
					<p>
						Pass holders will be able to stake Copium Coin holdings via the
						Copium Staking Platform, for a range of pre-defined periods at
						Investor Pass Holder only rates (see staking rates table).
					</p>
				</Animate>
				<More color="red" />
				<Animate>
					<p>
						Exclusive Pass Holder only staking rates will be substantially
						higher yield than public staking rates. These rates will only be
						available through ownership of the Copium Protocol Investor Pass and
						not to the public.
					</p>
				</Animate>
				<More color="red" />
				<Animate>
					<p>
						Pass Holders will be offered a one-time only, initial staking rate
						of 38% APY for a locked 36-month period. This high yield staking
						rate be available for a period of 30 days from the mint date of the
						Investor pass. (A min. staking quantity of 10k Copium Coins will
						apply).
					</p>
				</Animate>
				<More color="red" />
				<Animate>
					<p>
						Pass holders can additionally stake their Investor Pass in return
						for daily Copium Coin earnings. A Daily allocation of 10 Copium
						Coins per staked Investor Pass, will be guaranteed for a period of 2
						years (24 months), regardless of the price of Copium Coin at any
						point in time. Daily earnings will continue to accrue until the
						individual Passholder chooses to claim earnt coins (via a gas only
						transaction).
					</p>
				</Animate>
			</div>
		</div>
	)
}

export default Team
