import { FAQS } from '../../config/content'
import Accordion from '../Accordion/Accordion'
import Animate from '../Animate/Animate'
import useStyles from './FAQSection.styles'

const FAQSection: React.FC = () => {
	const classes = useStyles()

	return (
		<div className={classes.content}>
			{FAQS.map(faq => 
				<Animate key={faq.title}>
					<Accordion {...faq} />
				</Animate>
			)}
		</div>
	)
}

export default FAQSection
