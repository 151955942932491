import { createUseStyles } from 'react-jss'
import { text_shadow } from '../../config/colours'

const styles = {
	page: {
		minWidth: '100vw',
		minHeight: '50vh',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '8rem 0',
		'& h1, h2': {
			fontSize: '2em',
			textShadow: text_shadow,
		},
		'& p': {
			maxWidth: '50rem',
			fontSize: '1.3em',
		},
	},
	countdown: {
		margin: '2rem',
	},
	mint: {
		display: 'grid',
		gridTemplateColumns: '1fr 1fr',
		gap: '2em',
		marginBottom: '10rem',
	},
	barContainer: {
		width: '100%',
		maxWidth: '50rem',
		margin: '2rem 0 4rem 0',
	},
}

export default createUseStyles(styles)
