import {
	BrowserRouter,
	Navigate,
	Route,
	Routes,
	useLocation,
} from 'react-router-dom'
import { HomeRoute, ICORoute, InvestorPassRoute } from './routes'
import Home from './Home/Home'
import ICO from './ICO/ICO'
import { useEffect } from 'react'
import Mint from './Mint/Mint'

const ScrollToTop: React.FC = () => {
	const { pathname } = useLocation()
	useEffect(() => {
		document.documentElement.scrollTo({
			top: 0,
			left: 0,
			behavior: 'auto',
		})
	}, [pathname])

	return null
}

const Router: React.FC = () => {
	return (
		<BrowserRouter>
			<ScrollToTop />
			<Routes>
				<Route path={HomeRoute.path} element={<Home />} />
				<Route path={InvestorPassRoute.path} element={<Mint />} />
				<Route path={ICORoute.path} element={<ICO />} />
				<Route path="*" element={<Navigate to={HomeRoute.path} />} />
			</Routes>
		</BrowserRouter>
	)
}

export default Router
