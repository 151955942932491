import { Contract, ethers } from 'ethers'
import {
	createContext,
	FC,
	ReactNode,
	useContext,
	useEffect,
	useState,
} from 'react'
import PASS_ABI from '../../abi/passABI.json'
import ICO_ABI from '../../abi/icoABI.json'
import { getPassId, getICOId } from '../../config/chain'
import { Web3Context } from './Web3Context'

interface CtxProps {
	passContract?: Contract
	passContractId?: string
	icoContract?: Contract
	icoContractId?: string
}
interface Props {
	children: ReactNode
}

export const ContractContext = createContext<CtxProps>({})
const ContractContextProvider: FC<Props> = ({ children }) => {
	const { web3Provider } = useContext(Web3Context)

	const [passContract, setPassContract] = useState<Contract>()
	const [passContractId, setPassContractId] = useState<string>()

	const [icoContract, setIcoContract] = useState<Contract>()
	const [icoContractId, setIcoContractId] = useState<string>()

	useEffect(() => {
		getContracts()
	}, [web3Provider])

	const getContracts = async () => {
		if (!web3Provider) {
			return
		}

		const { chainId } = await web3Provider.getNetwork()
		const _passContractId = getPassId(chainId)
		const _icoContractId = getICOId(chainId)

		const signer = web3Provider.getSigner()

		if (_passContractId) {
			setPassContractId(_passContractId)
			const _passContract = new ethers.Contract(_passContractId, PASS_ABI, signer)
			setPassContract(_passContract)
		}

		if (_icoContractId) {
			setIcoContractId(_icoContractId)
			const _icoContract = new ethers.Contract(_icoContractId, ICO_ABI, signer)
			setIcoContract(_icoContract)
		}
	}

	return (
		<ContractContext.Provider
			value={{
				passContract,
				passContractId,
				icoContract,
				icoContractId,
			}}
		>
			{children}
		</ContractContext.Provider>
	)
}

export default ContractContextProvider
