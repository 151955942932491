import { createUseStyles } from 'react-jss'

const styles = {
	content: {
		minWidth: 'min(100%, 400px)',
		width: '80vw',
		padding: '2rem',
	},
}

export default createUseStyles(styles)
