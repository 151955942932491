import { createUseStyles } from 'react-jss'
import { blue, red, yellow } from '../../config/colours'
import { borderRadius } from '../../config/jss-vars'

const styles = {
	container: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	header: {
		display: 'inline-block',
		position: 'relative',
		textAlign: 'center',
		'@media (min-width: 768px)': {
			fontSize: '3rem',
		},
	},
	dash: {
		width: '3em',
		height: '6px',
		content: '""',
		backgroundColor: 'white',
		borderRadius,
		margin: '1em',
	},
	yellow: {
		composes: '$dash',
		backgroundColor: yellow,
	},
	red: {
		composes: '$dash',
		backgroundColor: red,
	},
	blue: {
		composes: '$dash',
		backgroundColor: blue,
	},
	large: {},
	small: {
		fontSize: '1.5em !important',
		'&:before': {
			width: '20%',
			marginLeft: '-30%',
		},
		'&:after': {
			width: '20%',
			marginLeft: '10%',
		},
	},
}

export default createUseStyles(styles)
