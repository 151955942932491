import { STAKING_INVESTOR_BENEFITS } from '../../config/content'
import Animate from '../Animate/Animate'
import BulletPoint from '../BulletPoint/BulletPoint'
import useStyles from './StakingSection.styles'
import StakingRateTable from '../StakingSection/StakingRateTable'
import More from '../More/More'
import Heading from '../Heading/Heading'

const StakingSection: React.FC = () => {
	const classes = useStyles()

	return (
		<div className={classes.content}>
			<p>
				Following the successful launch of the Copium Protocol Investor Pass and
				the presale / public launch of Copium Coin, will be the introduction of
				the Copium Protocol Staking Platform. Copium Staking is a non-custodial
				platform, meaning users will retain ownership of their staked tokens,
				which will remain locked within their wallet, for the selected staking
				period.
			</p>
			<More color="yellow" />
			<p>
				Users will be able to stake their Copium Coin holdings for a range of
				locked and unlocked periods, in exchange for high APY% interest rates.
				Interest rates will be proportionate to the locked time period of the
				selected staking contract. The longer the locked period, the higher
				the interest rate % will be.
			</p>
			<More color="yellow" />
			<p>
				Interest accrued during each locked staking time period, will be paid
				to the user in Copium Coin, upon expiry of the staking contract.
			</p>
			<More color="yellow" />
			<p>
				Should a user choose to prematurely cancel a current locked time
				period staking contract before the expiration date, the user will
				forfeit any and all accrued interest for the entire duration of the
				staking contract. There will be no additional penalty for exiting the
				staking contract.
			</p>
			<More color="yellow" />
			<p>
				Users will be able to view Copium Coin Holdings, including accrued,
				but not yet realized interest, for each staking contract, at any point
				in time via the user portal.
			</p>
			<More color="yellow" />
			<p>
				Users will be able to use features on the staking platform such as
				selecting and adjusting interest rate, and time period sliders to
				estimate potential earnings based on their individual chosen staking
				contract parameters.
			</p>

			<h3 className={classes.smallTitle}>
				Exclusive Investor Pass holder only staking benefits
			</h3>

			{STAKING_INVESTOR_BENEFITS.map(text => (
				<Animate key={text}>
					<BulletPoint text={text} color="yellow" size="small" />
				</Animate>
			))}

			<Heading color="yellow" size='small'>Staking Rates</Heading>
			<StakingRateTable />

			<div className={classes.spacer}></div>
			<Heading color="yellow" size='small'>Staked Investor Pass Rewards</Heading>
			<p>
				10 Copium Coins per day, will be accrued for each Copium Protocol
				Investor Pass that is staked.
				<br />
				<i>
					(Rewards are allocated to staked Pass Holders daily, however users
					will continue to earn accrue rewards until they choose to redeem their
					coins, via a gas only transaction.)
				</i>
			</p>
		</div>
	)
}

export default StakingSection
