import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import {
	CONTACT_LINK,
	COPIUM_MINING_LINK,
	PREMINT_LINK,
	PROJECT_NAME,
	TAC_LINK,
	TELEGRAM_LINK,
	TWITTER_LINK,
	WHITEPAPER_LINK,
} from '../../config/content'
import { HomeRoute, ICORoute } from '../../pages/routes'
import useStyles from './Footer.styles'

const Footer: React.FC = () => {
	const classes = useStyles()

	return (
		<footer className={classes.footer}>
			<div className={classes.logoGroup}>
				<img className={classes.logo} src="img/copium_logo.png" />
				<div>
					<h3>{PROJECT_NAME}</h3>
					<span>Decentralised Mining Investment</span>
				</div>
			</div>
			<div className={classes.tabletBreak} />
			<div className={classes.column}>
				<span className={classes.title}>Company</span>
				<Link to={ICORoute.path}>
					Presale ICO
				</Link>
				<HashLink smooth to={`${HomeRoute.path}#pass`}>
					Investor Pass
				</HashLink>
				<HashLink smooth to={`${HomeRoute.path}#coin`}>
					Copium Coin
				</HashLink>
				<HashLink
					smooth
					to={`${HomeRoute.path}#mining`}
				>
					Copium Mining
				</HashLink>
				<HashLink
					smooth
					to={`${HomeRoute.path}#staking`}
				>
					Staking
				</HashLink>
				<HashLink smooth to={`${HomeRoute.path}#faq`}>
					FAQ
				</HashLink>
				<a href={PREMINT_LINK} target="_blank">
					Mint Registration
				</a>
			</div>
			<div className={classes.column}>
				<span className={classes.title}>Socials</span>
				<a href={TWITTER_LINK} target="_blank">
					Twitter
				</a>
				<a href={TELEGRAM_LINK} target="_blank">
					Telegram
				</a>
			</div>
			<div className={classes.column}>
				<span className={classes.title}>Links</span>
				<a href={WHITEPAPER_LINK} target="_blank">
					Whitepaper
				</a>
				<a href={COPIUM_MINING_LINK} target="_blank">
					Copium Mining NZ
				</a>
				<a href={TAC_LINK} target="_blank">
					Terms and Conditions
				</a>
				<a href={CONTACT_LINK} target="_blank">
					Contact Us
				</a>
			</div>
		</footer>
	)
}

export default Footer
