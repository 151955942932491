import { MINING_TITLE } from '../../config/content'
import Animate from '../Animate/Animate'
import useStyles from './Mining.styles'

const Mining: React.FC = () => {
	const classes = useStyles()

	return (
		<div className={classes.container}>
			<div
				style={{ padding: '56.25% 0 0 0', position: 'relative', width: '100%' }}
			>
				<iframe
					src="https://player.vimeo.com/video/739134690?h=449f4a4ce8&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
					frameBorder="0"
					allow="autoplay; fullscreen; picture-in-picture"
					allowFullScreen
					style={{
						position: 'absolute',
						top: 0,
						left: 0,
						width: '100%',
						height: '100%',
					}}
					title="CopiumMiningFinal.mp4"
				></iframe>
			</div>
			<Animate>
				<h3 className={classes.title}>About {MINING_TITLE}</h3>
				<p>
					Copium Mining is a New Zealand registered, cryptocurrency mining
					company.
				</p>
				<p>
					Copium Mining has{' '}
					<span className={classes.highlight}>several mining facilities</span>{' '}
					worldwide, the largest of which is based in a small, isolated town at
					the foothills of NZ's beautiful Southern Alps, just outside Dunedin,
					Otago.
				</p>
				<p>
					This temperature controlled location is ideal for our mining facility
					for a multitude of different factors and is hugely unique in the fact
					that it is completely supplied by a surplus of{' '}
					<span className={classes.highlight}>
						100% Clean, Green, Renewable Hydroelectricity
					</span>
					, generated just a few kilometers away.
				</p>
				<p>
					This key factor is what allows us to operate a very efficient,
					profitable, low cost business model.
				</p>
				<p>
					Copium Mining has a successful, established, long term track record of
					operation and a respected reputation in the New Zealand Cryptocurrency
					industry.
				</p>
			</Animate>
			<Animate>
				<h3 className={classes.centerTitle}>Funds from the Investor Pass</h3>
				<p>
					Funds from the sale of the Copium Protocol Investor pass, will be used
					to purchase bulk quantities of the latest, and most power efficient
					generation of miners, Funds will also be used to sufficiently upgrade
					the infrastructure at the existing Copium Mining facility.
				</p>
				<p>
					Revenue generated by the Copium Mining facility will be used daily to
					purchase Copium Coin from the open market, Coins purchased via mining
					revenue will be sent to the token burn address , decreasing
					circulating supply, increasing scarcity, ensuring Copium Coin remains
					a deflationary asset.
				</p>
			</Animate>
		</div>
	)
}

export default Mining
