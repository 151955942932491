import Card from '../Card/Card'
import useStyles from './EcoSystem.styles'

const EcoSystem: React.FC = () => {
	const classes = useStyles()

	return (
		<div className={classes.row}>
			<Card image="img/investor_pass.png" title="Investor Pass" />
			<Card image="img/copium_coin_flat.png" title="Copium Coin" />
			<Card image="img/copium_mining_sq.png" title="Copium Mining" padded />
			<Card image="img/copium_coin_pile.png" title="Copium Staking" />
		</div>
	)
}

export default EcoSystem
