import useStyles from './Heading.styles'

type Color = 'red' | 'yellow' | 'blue'
type Size = 'large' | 'small'

interface Props {
	color: Color
	size?: Size
	children?: React.ReactNode
}

const Heading: React.FC<Props> = ({ color, size = 'large', children }) => {
	const classes = useStyles()

	return <div className={classes.container}>
		<span className={classes[color]}></span>
		<h2 className={`${classes.header} ${classes[size]}`}>{children}</h2>
		<span className={classes[color]}></span>
	</div>
}

export default Heading
