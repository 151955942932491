import { useMediaQuery } from 'react-responsive'
import { HashLink } from 'react-router-hash-link'
import { HomeRoute } from '../../pages/routes'
import Button from '../Button/Button'
import useStyles from './BulletPoint.styles'

type IconColor = 'yellow' | 'red' | 'blue'
type IconSize = 'small' | 'medium' | 'large'

interface Props {
	title?: string
	text: string
	color: IconColor
	padding?: boolean
	size?: IconSize
	button_text?: string
	button_hash?: string
	offset?: number
}

const BulletPoint: React.FC<Props> = ({
	title,
	text,
	color,
	size = 'large',
	padding = true,
	button_text,
	button_hash,
	offset,
}) => {
	const classes = useStyles()
	const isDesktop = !useMediaQuery({ query: '(max-width: 1024px)' })
	const translate = offset ? (offset % 2 === 0 ? 3 : -3) : 0
	return (
		<div
			className={`${padding ? classes.spacedOut : classes.noPadding}`}
			style={{ transform: isDesktop ? `translateX(${translate}vw)`: 'none' }}
		>
			<img className={classes[size]} src={`img/${color}_hex.png`} />
			<div className={classes.container}>
				{title && <h3 className={classes.title}>{title}</h3>}
				<div className={classes.text}>{text}</div>
				{button_text && button_hash && (
					<HashLink
						smooth
						to={`${HomeRoute.path}#${button_hash}`}
						className={classes.button}
					>
						<Button
							className="primary"
							onClick={() => true}
							text={button_text}
						/>
					</HashLink>
				)}
			</div>
		</div>
	)
}

export default BulletPoint
