import { white } from '../../config/colours'

export const styles = {
	bmBurgerButton: {
		position: 'fixed',
		width: '36px',
		height: '30px',
		top: '16px',
		right: '16px',
	},
	bmBurgerBars: {
		background: '#373a47',
	},
	bmBurgerBarsHover: {
		background: '#a90000',
	},
	bmCrossButton: {
		height: '24px',
		width: '24px',
	},
	bmCross: {
		background: '#bdc3c7',
	},
	bmMenuWrap: {
		position: 'fixed',
		height: '100%',
		top: '0',
		overflow: 'hidden',
	},
	bmMenu: {
		background: '#373a47',
		padding: '2.5em 1.5em 0',
		fontSize: '1.15em',
	},
	bmMorphShape: {
		fill: '#373a47',
	},
	bmItemList: {
		color: white,
		padding: '0.8em',
	},
	bmItem: {
		marginBottom: '1em',
	},
	bmOverlay: {
		background: 'rgba(0, 0, 0, 0.3)',
	},
}
