// General
export const PROJECT_NAME = 'Copium Protocol'

// Mint pages
export const MINT_PAGE_TITLE = 'Mint your Investor Pass now!'

export const MINT_NOSALE_TITLE = 'Sale not yet live!'
export const MINT_NOSALE_SUBTEXT = 'Come back soon!'

export const MINT_SOLDOUT_TITLE = 'Sold out!'
export const MINT_SOLDOUT_SUBTEXT = 'Find us on Opensea!'

export const MINT_BTN_TEXT = 'Mint'
export const MINT_SUCCESS = 'Congrats on minting your NFT!'
export const MINT_FAIL_POOR = 'You don\'t have enought ETH!'
export const MINT_FAIL_GENERIC = 'Transaction declined!'
export const MINT_NOT_ALLOWLISTED = 'You aren\'t on the list!'

export const ICO_SALEOFF = 'ICO coming soon...'
export const ICO_SOLDOUT = 'ICO Sold Out!'

export const WEBSITE_OUTDATED =
	'The website is outdated!\nPlease clear your browser cache.'

export const TX_PENDING = 'Tx Pending...'

// Social links
export const TELEGRAM_LINK = 'https://t.me/copiumprotocol'
export const TWITTER_LINK = 'https://twitter.com/copiumprotocol'
export const OPENSEA_LINK = ''
export const AOCOLLAB_LINK = 'https://block.aocollab.tech/'
export const PREMINT_LINK = 'https://www.premint.xyz/copium-protocol'
export const COPIUM_MINING_LINK = 'https://www.copiummining.com'
export const WHITEPAPER_LINK = 'https://copiumprotocol.gitbook.io/docs'
export const TAC_LINK =
	'https://copiumprotocol.gitbook.io/docs/community-standard/terms-and-conditions'
export const CONTACT_LINK = 'mailto:copium@copiumprotocol.io'

// Landing Page
export const HERO_TITLE = 'Copium Protocol'
export const HERO_DESCRIPTION = 'Decentralised Mining Investment'
export const HERO_BUTTON_TEXT = 'Early Registration On Now'

export const TEAM_MEMBER_TITLE = 'The Copium Team'
export const TEAM_MEMBERS = [
	{
		name: 'Tom Webb',
		image: 'avatar1.png',
		description: 'Founder',
		twitter: '',
	},
	{
		name: 'Carl Shaw',
		image: 'avatar2.png',
		description: 'Founder',
		twitter: '',
	},
	{
		name: 'Rumbles.eth',
		image: 'avatar3.png',
		description: 'Business Advisor',
		twitter: '',
	},
	{
		name: 'Michael Standen',
		image: 'avatar4.png',
		description: 'Full Stack Developer',
		twitter: '',
	},
]

export const ECOSYSTEM_TITLE = 'Copium Ecosystem'
export const ECOSYSTEM_DESCRIPTION =
	'Copium Protocol is a multi-pronged Blockchain mining investment project.'
export const ECOSYSTEM_BULLETS = [
	{
		title: 'COPIUM COIN PRESALE',
		text: 'Copium Coin presale ICO will run for a period of 60 days prior to the official public launch of the coin, or until the designated pre-sale hard cap is reached. Presales of Copium Coin will be available from the project website.',
	},
	{
		title: 'COPIUM PROTOCOL INVESTOR PASS',
		text: '10k Investor Pass collection launch via Dutch auction sale at 3.5 ETH start price. First 2k people to register early interest via Premint link on the Copium Protocol website, will receive a guaranteed mint spot at a discounted fixed price of 3 Eth.',
	},
	{
		title: 'FUNDS ALLOCATED',
		text: 'Funds from Investor Pass sales used to purchase additional mining equipment and upgrade infrastructure at the current Copium Mining facility in NZ. Daily mining revenue from the facility, will be used to purchase Copium Coin from the open market.',
	},
	{
		title: 'COPIUM COIN BURN PROTOCOL',
		text: 'All Copium Coins purchased daily via Copium Mining revenue will be sent directly to a burn address, reducing supply, increasing scarcity, ensuring Copium Coin remains a deflationary asset.',
	},
	{
		title: 'COPIUM COIN AIRDROP',
		text: 'Investor Pass holders will be eligible for the initial Copium Coin airdrop. Each pass held will receive 10,000 Copium Coins. Each additional pass held will be eligible for additional airdrop amount.',
	},
	{
		title: 'INVESTOR PASS HOLDER UTILITY',
		text: `All Investor pass holders will be eligible to stake their passes in exchange for daily Copium coin earnings, guaranteed for a minimum period of 24 months regardless of the Copium coin price.

		Investor Pass holders who choose to stake their Copium coin holdings via the Copium Staking Platform for predefined locked and unlocked time periods, will qualify for exclusive high yield, Pass Holder only staking rates.`,
	},
	{
		title: 'COPIUM COIN STAKING PLATFORM',
		text: 'Following the successful launch of Copium Coin, will be the introduction of the Copium Staking Platform. Users will be able to stake coin holdings for locked and unlocked periods at various high yield staking rates.',
	},
]

export const COIN_TITLE = 'Copium Coin'
export const COIN_BULLETS = [
	'Copium Coin will be launched at the same time as the Investor Pass NFT collection opens for Dutch Auction.',
	'Presale of Copium coin will run for 60 days prior to launch in order to generate initial liquidity at a cost of $0.10 USD per coin.',
	'Copium Coin staking for Investor Pass Holders will be available as soon as the mint has closed, or a 7-day period has elapsed from initial mint opening.',
	'Revenue generated by the mining facility will be used daily to purchase Copium Coin from the open market. Coins purchase with mining revenue will then be sent to token burn address, decreasing circulating supply, increasing scarcity.',
	'Public sales of the coin will be available via Uniswap from the time of launch.',
	'Copium coin is the native ERC-20 (Ethereum based) token, that powers the Copium Protocol ecosystem.',
]
export const TOTAL_SUPPLY_TITLE = 'Total Supply'
export const TOTAL_SUPPLY = '1,000,000,000'
export const COIN_INFO = [
	{
		title: 'INITIAL AIRDROP (10%)',
		amount: '100,000,000',
	},
	{
		title: 'DEVELOPMENT FUND (10%)',
		amount: '100,000,000',
	},
	{
		title: 'REWARDS POOL (7.3%)',
		amount: '73,000,000',
		sub: 'STAKED INVESTOR PASS REWARDS POOL',
	},
	{
		title: 'FOUNDERS (10%)',
		amount: '100,000,000',
	},
	{
		title: 'PRESALE ALLOCATION',
		amount: '25M - 50M',
		sub: '2.5% SOFT CAP - 5% HARD CAP',
	},
	{
		title: 'LIQUIDITY (3%)',
		amount: '30,000,000',
	},
	{
		title: 'PROJECT TEAM (0.7%)',
		amount: '7,000,000',
	},
	{
		title: 'TOTAL ALLOCATION (46%)',
		amount: '460,000,000',
	},
]

export const PASS_TITLE = 'Investor Pass'

export const MINING_TITLE = 'Copium Mining'

export const STAKING_TITLE = 'Staking Platform'

export const STAKING_INVESTOR_BENEFITS = [
	'Investor Pass holders will be able to stake their Copium Coin holdings for a range of pre-defined periods at special Investor Pass only rates (see staking rates table).',
	'Exclusive Pass Holder only staking rates will be substantially higher yield than public staking rates. Starting at 12% APY, for a locked period of 30 days, increasing incrementally depending on the chosen period of staking. These rates will only be available through ownership of the Copium Protocol Investor Pass and not to the public.',
	'Pass holders can additionally stake their Investor Pass in return for daily Copium Coin earnings. Daily earnings will continue to accrue until the individual Passholder chooses to claim earnt coins (via a gas only transaction). A Daily allocation of 10 Copium Coins per staked Investor Pass, will be guaranteed for a period of 2 years (24 months), regardless of the price of Copium Coin at any point in time.',
	'Pass Holders will be offered a one-time only, initial staking rate of 38% APY for a locked 36-month period. This high yield staking rate will only be available to Pass Holders for 30 days from the mint date of the Investor pass. In order to qualify for this offer, Pass Holders must stake an amount of 10,000 Copium Coins or greater.',
]

export const FAQ_TITLE = 'FAQ'
export const FAQS = [
	{
		title: 'WHAT IS COPIUM PROTOCOL?',
		expandedText:
			'Copium Protocol is an innovative, crypto mining investments ecosystem, built on the Ethereum blockchain.',
	},
	{
		title: 'WHAT IS CRYPTOCURRENCY MINING?',
		expandedText:
			'Cryptocurrency mining is the practice of individual computers or ‘nodes’ performing complex computational transactions which are recorded onto the blockchain, in exchange for small amounts of that particular blockchain native currency. This is called a proof of work (POW) blockchain.',
	},
	{
		title: 'IS IT SAFE TO INVEST IN COPIUM PROTOCOL AND ITS PRODUCTS?',
		expandedText:
			'No investment is 100% safe. The cryptocurrency industry as a whole is unregulated and is also known for its extremely volatile and sometimes unpredictable price fluctuations. We recommend you always do your own research before investing into any cryptocurrency based product. You should never invest more than you can afford to lose.',
	},
	{
		title: 'IS THERE A COPIUM WALLET?',
		expandedText:
			'Copium Coin is an ERC-20 Ethereum network based token. Any wallet capable of storing Ethereum, can also store Copium Coin. We recommend the use of METAMASK browser extension wallet. For cold storage of large amounts of cryptocurrency over the value of $10,000, we recommend the use of a TREZOR Hardware wallet.',
	},
	{
		title: 'WHAT IS THE COPIUM PROTOCOL INVESTOR PASS, AND WHAT DOES IT DO?',
		expandedText:
			'The Copium Protocol Investor Pass, is in basic terms, an early investor VIP access pass to the entire Copium Protocol ecosystem, in the form of an NFT. It entitles the Pass Holder to exclusive real-word benefits, rewards and opportunities that will only ever be accessible via the pass.',
	},
	{
		title: 'IS THE INVESTOR PASS REALLY WORTH IT?',
		expandedText:
			'The Investor Pass is definitely worth it. We designed the pass with one goal in mind. To provide Pass Holders the opportunity to substantially grow their initial investment, while also supporting an entire ecosystem of products which are also designed to do just that.',
	},
	{
		title: 'WHAT IS COPIUM COIN?',
		expandedText:
			'Copium Coin is the native ERC-20 token which powers the entire Copium Protocol ecosystem.',
	},
	{
		title: 'DOES COPIUM PROTOCOL STORE OR RETAIN MY STAKED FUNDS?',
		expandedText:
			'Copium staking is a non-custodial staking platform, meaning all funds invested via the platform remain the exclusive property of the investor, and are the investors sole responsibility to keep safe.',
	},
	{
		title: 'CAN I HAVE MULTIPLE STAKING CONTRACTS AT ONCE?',
		expandedText:
			'Yes, you may have as many staking contracts as you require at any one time.',
	},
	{
		title: 'WHAT IS THE MINIMUM STAKING PERIOD?',
		expandedText:
			'The minimum staking period for both Copium Protocol Investor Pass holders and for non-pass holders is 30 days.',
	},
	{
		title: 'WHAT IS THE MAXIMUM STAKING PERIOD AVAILABLE?',
		expandedText:
			'36 Months is the current maximum staking period, and is only available to Investor Pass holders. The maximum staking period for public users is currently 24 months.',
	},
	{
		title: 'WHEN DO I RECEIVE MY STAKING INTEREST PAYMENT?',
		expandedText:
			'Upon expiry of the staking contract you will receive your ROI, paid in Copium Coin.',
	},
	{
		title: 'CAN I ALTER A CURRENT STAKING CONTRACT?',
		expandedText:
			'No, once a staking contact is undertaken it cannot be altered.',
	},
	{
		title:
			'WHAT IF I NEED TO CANCEL A STAKING CONTRACT BEFORE THE EXPIRY DATE?',
		expandedText:
			'Should you need to cancel a current staking contract before the scheduled expiry date of the contract for any reason, you may do so. However, any interest accrued in the staking period up until the date of early cancellation, will be forfeited.',
	},
	{
		title: 'CAN I MERGE MULTIPLE STAKING CONTRACTS?',
		expandedText:
			'No, once a staking contract is underway it cannot be altered.',
	},
]
