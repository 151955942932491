import Animate from '../Animate/Animate'
import useStyles from './Stat.styles'

interface Props {
	title: string
	amount: string
	sub?: string
	animated?: boolean
	fullWidth?: boolean
	small?: boolean
}

const Stat: React.FC<Props> = ({
	title,
	amount,
	sub,
	animated = true,
	fullWidth,
	small,
}) => {
	const classes = useStyles()

	const inner = (
		<div className={fullWidth ? classes.largeContainer : classes.container}>
			<span className={classes.title}>{title}</span>
			<span className={small ? classes.smallAmount : classes.amount}>
				{amount}
			</span>
			{sub && <span className={classes.sub}>{sub}</span>}
		</div>
	)

	if (animated) {
		return <Animate>{inner}</Animate>
	}
	return inner
}

export default Stat
