import { Link } from 'react-router-dom'
import {
	COIN_BULLETS,
	COIN_INFO,
	TOTAL_SUPPLY,
	TOTAL_SUPPLY_TITLE,
} from '../../config/content'
import { ICORoute } from '../../pages/routes'
import Animate from '../Animate/Animate'
import BulletPoint from '../BulletPoint/BulletPoint'
import Button from '../Button/Button'
import Heading from '../Heading/Heading'
import Stat from '../Stat/Stat'
import useStyles from './CoinSection.styles'

const CoinSection: React.FC = () => {
	const classes = useStyles()

	return (
		<div className={classes.container}>
			<div
				style={{ padding: '56.25% 0 0 0', position: 'relative', width: '100%' }}
			>
				<iframe
					src="https://player.vimeo.com/video/739150632?h=17ddaf0d02&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
					frameBorder="0"
					allow="autoplay; fullscreen; picture-in-picture"
					allowFullScreen
					style={{
						position: 'absolute',
						top: 0,
						left: 0,
						width: '100%',
						height: '100%',
					}}
					title="Copium Coin"
				></iframe>
			</div>
			<div className={classes.flexCentered}>
				<Link to={ICORoute.path} className={classes.link}>
					<Button
						onClick={() => true}
						className="yellow"
						text="Buy Copium Now"
					/>
				</Link>
			</div>
			<div className={classes.rowCenter}>
				{COIN_BULLETS.map(coin => (
					<Animate key={coin}>
						<BulletPoint
							color={'yellow'}
							text={coin}
							size="medium"
							padding={false}
						/>
					</Animate>
				))}
			</div>

			<div className={classes.row}>
				<div className={classes.flexCentered}>
					<img className={classes.coinImage} src="img/copium_coin.png" />
					<Heading color="yellow" size="small">
						Copium Coin Tokenomics
					</Heading>
					<Stat title={TOTAL_SUPPLY_TITLE} amount={TOTAL_SUPPLY} />
				</div>
			</div>

			<div className={classes.row}>
				{COIN_INFO.map(info => {
					return (
						<Stat
							key={info.title}
							title={info.title}
							amount={info.amount}
							sub={info.sub}
						/>
					)
				})}
			</div>
		</div>
	)
}

export default CoinSection
