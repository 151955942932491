import { createUseStyles } from 'react-jss'
import { page_background, red, white } from '../../config/colours'
import { headingFontFamily } from '../../config/jss-vars'

const styles = {
	header: {
		position: 'sticky',
		top: 0,
		padding: '1em',
		zIndex: 1000,
		backgroundColor: page_background,
	},
	title: {
		display: 'flex',
		color: white,
		alignItems: 'center',
		fontSize: '0.7rem',
		fontFamily: headingFontFamily,
		position: 'fixed',
		top: '8px',
		'& img': {
			padding: '0 0.5rem',
		},
	},
	burger: {
		composes: '$header',
		height: '28px',
	},
	nav: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		gap: '3em',
		width: '100%',
	},
	link: {
		color: white,
		textDecoration: 'none',
		'&:hover': {
			textDecoration: 'underline',
		},
	},
	linkSelected: {
		composes: '$link',
		color: red,
	},
	socials: {
		display: 'flex !important',
		flexDirection: 'row',
		alignItems: 'center',
		gap: '0.5em',
		fontSize: '1.5em',
		color: white,
		'& svg path': {
			fill: white,
		},
	},
}

export default createUseStyles(styles)
