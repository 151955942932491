import Button, { ButtonProps } from '../Button/Button'
import useStyles from './CallToAction.styles'

type Color = 'yellow' | 'red' | 'blue'

interface Props {
	title: string
	text?: string
	button: ButtonProps
	color: Color
}

const CallToAction: React.FC<Props> = ({ title, text, button, color }) => {
	const classes = useStyles()

	return (
		<div className={classes[color]}>
			<div className={classes.textSection}>
				<h3>{title}</h3>
				{text && <p>{text}</p>}
			</div>
			<div className={classes.button}>
				<Button {...button} />
			</div>
		</div>
	)
}

export default CallToAction
