import { createUseStyles } from 'react-jss'
import { black, text_shadow, white } from '../../config/colours'
import { borderRadius, headingFontFamily } from '../../config/jss-vars'

const styles = {
	footer: {
		width: '100%',
		display: 'flex',
		flexWrap: 'wrap',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'flex-start',
		gap: '2em',
		padding: '2em 1em',
		border: `0.5em solid ${white}`,
		borderRadius,
		boxSizing: 'border-box',
		color: white,
		margin: 'auto',
		textShadow: text_shadow,
		textAlign: 'center',
		'& a': {
			color: white,
		},
	},
	tabletBreak: {
		'@media (max-width: 1440px)': {
			flexBasis: '100%',
			width: 0,
		}
	},
	logoGroup: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		justifyContent: 'center',
		alignItems: 'center',
		fontFamily: headingFontFamily,
		textTransform: 'uppercase',
		'& h3': {
			fontSize: '1.8em',
			margin: 0,
		},
	},
	logo: {
		width: '100px',
		background: black,
		padding: '0 1em 1em 0',
	},
	column: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		gap: '0.5em',
	},
	title: {
		fontSize: '1.5rem',
		marginBottom: '0.2em',
		fontFamily: headingFontFamily,
		textTransform: 'uppercase',
		textShadow: text_shadow,
	},
}

export default createUseStyles(styles)
