import { createUseStyles } from 'react-jss'

const styles = {
	container: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		margin: '2rem',
	},
	image: {
		width: '50px',
		padding: '1rem',
	},
}

export default createUseStyles(styles)
