import { createUseStyles } from 'react-jss'
import { text_shadow } from '../../config/colours'
import { headingFontFamily } from '../../config/jss-vars'

const styles = {
	page: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		width: '100vw',
		overflowX: 'hidden',
		position: 'relative',
		'& h1': {
			fontFamily: headingFontFamily,
			textShadow: text_shadow,
			textTransform: 'uppercase',
			fontSize: '4em',
			margin: '0',
		},
	},
	section: {
		width: '100%',
		minHeight: '50vh',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '4em 0em',
		'& h2, h3': {
			fontFamily: headingFontFamily,
			textShadow: text_shadow,
			textTransform: 'uppercase',
		},
	},
	content: {
		maxWidth: '50rem',
		padding: '2rem',
	},
	hero: {
		composes: '$section',
		height: '80vh',
		zIndex: '500',
		background: '#000',
		'@media (max-width: 1024px)': {
			paddingTop: 0,
			backgroundColor: 'black',
		},
		textAlign: 'center',
		'& h1': {
			fontSize: '3rem',
			zIndex: '100',
			textShadow: text_shadow,
		},
		'& p': {
			maxWidth: '50rem',
			fontSize: '1.3em',
			fontFamily: headingFontFamily,
			textShadow: text_shadow,
		},
	},
	primaryLogo: {
		width: '250px',
		padding: '2rem',
	},
	box: {
		borderBottom: 'none',
		padding: '2rem',
		borderRadius: '5px',
		'& > *': {
			zIndex: 2,
			position: 'relative',
		},
	},
	video: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100vw',
		height: '100vh',
		marginTop: '30vh',
		zIndex: '-100',
		'@media (min-width: 478px)': {
			height: '120vh',
			marginTop: '0',
		},
		'@media (min-width: 1024px)': {
			height: '120vh',
			marginTop: '-20vh',
			filter: 'brightness(0.5)',
		},
	},
	row: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	alignedRow: {
		composes: '$row',
		alignItems: 'center',
	},
	largeText: {
		composes: '$content',
		fontSize: '2.5rem',
		textAlign: 'center',
		padding: '10rem 1rem',
	},
	flexCentered: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '4rem',
		flexWrap: 'wrap',
	},
	teamTitleDesktop: {
		transform: 'rotate(-90deg)',
		fontSize: '90px !important',
		position: 'absolute',
		left: '-20rem',
		padding: '0',
		margin: '0 !important',
	},
	image: {
		display: 'block',
		margin: 'auto',
	},
	centered: {
		textAlign: 'center',
	},
	'@media (max-width: 1024px)': {
		row: {
			flexDirection: 'column',
			alignContent: 'center',
		},
	},
}

export default createUseStyles(styles)
